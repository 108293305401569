
.collection {
    overflow: hidden;
}

.collection__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 726px;
    padding: 280px 0;
}

.collection__bottle {
    z-index: 1;
    position: absolute;
    height: auto;
    @include transition-all(1s);
    opacity: 0;

    &--twilight,
    &--flash {
        left: -185px;
        @include transform(translateX(-100px));
    }
    &--dawn {
        right: -190px;
        @include transform(translateX(100px));
    }

    @media screen and (max-width: 1150px) {

        &--twilight,
        &--flash {
            left: -110px;
            width: 80%;
        }
        &--dawn {
            right: -90px;
            width: 60%;
        }
    }
    @media screen and (max-width: 1024px) {

        &--twilight,
        &--flash {
            left: -9.5vw;
        }
        &--dawn {
            right: -8vw;
        }
    }
    @media screen and (max-width: 900px) {

        &--twilight,
        &--flash {
            left: -40px;
        }
        &--dawn {
            right: -20px;
        }
    }
    @media screen and (max-width: 768px) {

        &--twilight,
        &--flash {
            left: -33px;
        }
        &--dawn {
            right: -15px;
        }
    }
    @media screen and (max-width: 700px) {

        &--twilight,
        &--flash {
            left: -35px;
        }
    }

    &.animate {
        @include transform(translateX(0));
        opacity: 1;
    }
}


/* nazev kolekce */

.collection__heading-wrapper {
    position: relative;
    line-height: (173/144);
    font-family: 'Nikola', serif;
    font-size: 144px;
    color: #6C6C6C;

    &--twilight {
        opacity: .1;
    }
}

.collection__heading {
    color: #6C6C6C;

    &--dawn {
        color: #fff;
    }
    &--twilight {
        color: #292929;
        //color: rgb(41, 41, 41);
    }
}

.collection__heading-shape {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-text-stroke: .5px #6C6C6C;
    -webkit-text-fill-color: transparent;

    &--dawn {
        -webkit-text-stroke: .3px #fff;
    }
    &--twilight {
        -webkit-text-stroke: .5px #292929;
    }
}


/* doprovodny text */

.collection__text-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 35px;
    color: #fff;

    &--twilight {
        color: #000;
    }
    &--dawn {
        color: #000;
    }
}

.collection__subheading {
    font-size: 25px;
    font-weight: 500;
}

.collection__para {
    line-height: (22/15);
    margin-top: 30px;
    font-size: 15px;
    font-weight: 500;
}

.collection__link {
    margin-top: 30px;
}

@media screen and (max-width: 1150px) {

    .collection__content {
        padding: 175px 0;
    }
}
@media screen and (max-width: 900px) {

    .collection__content {
        padding: 150px 0;
    }

    .collection__heading-wrapper {
        font-size: 124px;
    }

    .collection__text-wrapper {
        grid-template-columns: repeat(2, 1fr);
        margin: 35px auto 0;
        max-width: 650px;
    }
}
@media screen and (max-width: 768px) {

    .collection__content {
        padding: 155px 0;
    }

    .collection__heading-wrapper {
        font-size: 120px;
    }

    .collection__text-wrapper {
        margin-top: 10px;
    }
}
@media screen and (max-width: 767px) {

    .collection__para,
    .collection__link {
        margin-top: 20px;
    }
}
@media screen and (max-width: 700px) {

    .collection__content {
        padding: 130px 0;
    }

    .collection__heading-wrapper {
        font-size: 110px;
    }
}
@media screen and (max-width: 600px) {

    .collection__content {
        padding: 110px 0;
    }

    .collection__heading-wrapper {
        font-size: 90px;
    }

    .collection__subheading {
        font-size: 23px;
    }

    .collection__text-wrapper {
        margin-top: 10px;
    }
}
@media screen and (max-width: 540px) {

    .collection__heading-wrapper {
        font-size: 70px;
    }
}
@media screen and (max-width: 539px) {

    .collection__content {
        padding: 50px 0;
    }

    .collection__bottle {
        position: relative;
        top: 0;
        display: block;
        margin: 0 auto;

        &--twilight,
        &--flash {
            left: 20px;
            width: 300px;
            @include transform(translateX(-50px));
        }
        &--dawn {
            right: 0;
            width: 240px;
            @include transform(translateX(50px));
        }

        &.animate {
            @include transform(translateX(0));
        }
    }

    .collection__heading-wrapper {
        font-size: 45px;
    }

    .collection__heading-shape,
    .collection__heading {
        position: absolute;
        left: 20%;

        &--twilight {
            top: -240px;
        }
        &--dawn {
            top: -245px;
        }
        &--flash {
            top: -225px;
        }
    }

    .collection__text-wrapper {
        display: block;
        text-align: center;
        max-width: 300px;
        margin-top: 20px;
    }

    .collection__para,
    .collection__link {
        margin-top: 25px;
    }
}
@media screen and (max-width: 425px) {

    .collection__text-wrapper {
        max-width: 225px;
    }

    .collection__subheading {
        font-size: 20px;
    }

    .collection__para {
        font-size: 14px;
    }
}