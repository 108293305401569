
#login-panel {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 666;
  background: rgba(27, 28, 36, 0.55);

  pointer-events: none;
  opacity: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  -webkit-transition: opacity .3s 0s, height 0s .3s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, height 0s .3s, visibility 0s .3s;
  -ms-transition: opacity .3s 0s, height 0s .3s, visibility 0s .3s;
  -o-transition: opacity .3s 0s, height 0s .3s, visibility 0s .3s;
  transition: opacity .3s 0s, height 0s .3s, visibility 0s .3s;
}
#login-panel.active {
  pointer-events: auto;
  opacity: 1;
  height: 100%;
  visibility: visible;

  -webkit-transition-delay: 0s, 0s, 0s;
  -moz-transition-delay: 0s, 0s, 0s;
  -ms-transition-delay: 0s, 0s, 0s;
  -o-transition-delay: 0s, 0s, 0s;
  transition-delay: 0s, 0s, 0s;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

  #login-panel .modal-panel {
    display: none;
  }
  #login-panel.active .modal-panel {
    display: block;
  }
}

#login-panel .modal-panel {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

  z-index: 777;
  position: relative;
  width: 370px;
  padding: 45px 20px 20px 20px;
  text-align: left;
  line-height: normal;
  letter-spacing: 0;
  color: #636363;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);

  overflow: hidden;
  border: none;
}

#login-panel .modal-panel .close {
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 14px;
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #797979;
  font-size: 34px;
  font-weight: 300;
  box-sizing: content-box;
  border: 6px solid transparent;
  border-radius: 5px;
  outline: none;
}
#login-panel .modal-panel .close:focus {
  border-color: #dedede;
  background-color: #dedede;
}
#login-panel .modal-panel .close:hover {
  color: #636363;
}

#login-panel form input {
  padding: 13px 12px;
  width: 100%;
  font-size: 15px;
  margin-top: 8px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  border-radius: 4px;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-bottom: 1px solid #bfbfbf;
}
#login-panel form input:hover {
  border: 1px solid #a2a2a2;
}
#login-panel form input:focus {
  border: 1px solid #a2a2a2;
}

#login-panel form button {
  display: inline-block;
  line-height: 45px;
  margin-top: 21px;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 15px;
  background-color: #4aa76a;
  -webkit-box-shadow: 0 2px 0 0 #388e55;
  box-shadow: 0 2px 0 #388e55;
}
#login-panel form button:hover {
  background-color: #479e64;
}
#login-panel form button:active {
  background-color: #43965f;
}
#login-panel form button:focus {
  outline: none;
}

#login-panel form ul.error {
  padding: 0 5px;
  padding-top: 30px;
  color: #E23E3E;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  list-style-type: none;
}


#login-panel form::-webkit-input-placeholder {
  color: #999999;
}
#login-panel form :-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
#login-panel form::-moz-placeholder {  /* Firefox 19+ */
  color: #999999;
}
#login-panel form :-ms-input-placeholder {
  color: #999999;
}


/*********** LOGIN BUTTONY ***********/

#login-panel p {
  padding-top: 20px;
  margin: 35px 0 11px;
  text-align: center;
  font-size: 13.5px;
  color: #a6a6a6;
  border-top: 1px solid #e6e6e6;
}

.social-media-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.social-media-btns a {
  display: block;
  padding: 10px 15px;
  margin-top: 10px;
  width: 30%;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  background-color: #3a579a;
  -webkit-box-shadow: 0 2px 0 0 #314880;
  box-shadow: 0 2px 0 #314880;
}
.social-media-btns a:hover {
  background-color: #314c82;
}

.social-media-btns .twitter {
  background-color: #00abf0;
  -webkit-box-shadow: 0 2px 0 0 #008dc6;
  box-shadow: 0 2px 0 #008dc6;
}
.social-media-btns .twitter:hover {
  background-color: #00a1db;
}

.social-media-btns .google {
  background-color: #dd493d;
  -webkit-box-shadow: 0 2px 0 0 #ba3e33;
  box-shadow: 0 2px 0 #ba3e33;
}
.social-media-btns .google:hover {
  background-color: #c7433a;
}


@media screen and (max-height: 380px), screen and (max-width: 370px) {

  #login-panel .modal-panel {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

}

@media screen and (max-width: 370px) {

  #login-panel p {
    padding-top: 15px;
    margin: 25px 0 11px;
  }

  .social-media-btns {
    flex-direction: column;
  }

  .social-media-btns a {
    width: 100%;
    margin-top: 13px;
  }
}

@media screen and (max-height: 380px) {

  #login-panel form button {
    line-height: 35px;
  }

  #login-panel form input {
    padding: 8px 12px;
  }

  #login-panel p {
    padding-top: 12px;
    margin: 22px 0 11px;
  }

  .social-media-btns {
    flex-direction: row;
  }

  .social-media-btns a {
    padding: 7px 15px;
  }
}



/******************* Social media login *******************/

#social-login-panel .modal-panel {
  width: 400px;
}

#social-login-panel .social-media-btns a {
  width: 100%;
  margin: 0 5px;
}

#social-login-panel .auth {
  margin: 10px 0 20px;
  font-size: 15px;
}



/*TODO upravit RWD*/

@media screen and (max-width: 400px) and (orientation: portrait) {

  #social-login-panel .modal-panel {
    width: 100%;
  }
}