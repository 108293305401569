
@font-face {
    font-family: 'Cera';
    src: url('fonts/CeraPro-Regular.eot');
    src: url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Regular.woff2') format('woff2'),
    url('fonts/CeraPro-Regular.woff') format('woff'),
    url('fonts/CeraPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nikola';
    src: url('fonts/Nikola-Black.eot');
    src: url('fonts/Nikola-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/Nikola-Black.woff2') format('woff2'),
    url('fonts/Nikola-Black.woff') format('woff'),
    url('fonts/Nikola-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera';
    src: url('fonts/CeraPro-Bold.eot');
    src: url('fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Bold.woff2') format('woff2'),
    url('fonts/CeraPro-Bold.woff') format('woff'),
    url('fonts/CeraPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera';
    src: url('fonts/CeraPro-Black.eot');
    src: url('fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Black.woff2') format('woff2'),
    url('fonts/CeraPro-Black.woff') format('woff'),
    url('fonts/CeraPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera';
    src: url('fonts/CeraPro-Medium.eot');
    src: url('fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Medium.woff2') format('woff2'),
    url('fonts/CeraPro-Medium.woff') format('woff'),
    url('fonts/CeraPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@import "mixins/rules.scss";
@import "common/reset.scss";
@import "common/flashes.scss";
@import "common/login-panel.scss";
@import "common/magnific-popup.scss";


::-moz-selection {
    color: #000;
    background-color: rgba(224, 195, 163, .9);
}

::selection {
    color: #000;
    background-color: rgba(224, 195, 163, .9);
}


:root {
    --header-height: 80px;
}

@media screen and (max-width: 425px) {

    :root {
        --header-height: 70px;
    }
}
@media screen and (max-width: 375px) {

    :root {
        --header-height: 65px;
    }
}

body {
    color: #000;
    background: #fff;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
}
body.scroll-disabled {
    overflow: hidden;
}



$max-layout-width: 1070px;



/* Obsah webu */

.main-wrapper:not(.main-wrapper--hp) {
    //padding-top: var(--header-height);

    // 100px je vyska footeru
    min-height: calc(100vh - 100px);

    @media screen and (max-width: 1024px) {
        min-height: calc(100vh - 90px);
    }
    @media screen and (max-width: 425px) {
        min-height: calc(100vh - 85px);
    }
    @media screen and (max-width: 767px) {
        min-height: calc(100vh - 75px);
    }
}

.content-wrapper {
    width: 100%;
    padding: 0 45px;
    background-color: #fff;

    @media screen and (max-width: 768px) {
        padding: 0 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 0 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 20px;
    }
}

.content-wrapper--twilight {
    background-color: #fff;
}
.content-wrapper--dark {
    background-color: #000;
}
.content-wrapper--light {
    background-color: #f2f2f2;
}
.content-wrapper--beige {
    background-color: #e9d8cd;
}

.content {
    max-width: $max-layout-width;
    margin: 0 auto;
    padding: 80px 0;

    @media screen and (max-width: 900px) {
        padding: 70px 0;
    }
    @media screen and (max-width: 768px) {
        padding: 65px 0;
    }
    @media screen and (max-width: 425px) {
        padding: 60px 0;
    }
    @media screen and (max-width: 375px) {
        padding: 55px 0;
    }
}



/* nadpisy */

.heading {
    line-height: (40/35);
    font-size: 35px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        font-size: 32px;
    }
    @media screen and (max-width: 375px) {
        font-size: 30px;
    }
}



/* tlacitka */

.more-btn {
    position: relative;
    display: inline-block;
    padding-right: 24px;
    font-size: 16px;
    font-weight: 900;
    white-space: nowrap;
    @include transition(none);

    &:after {
        @include before-after(0, auto, 19px);
        content: '\27F6';
        right: 0;
        /*font-family: 'Cera', serif;
        font-size: 16px;
        font-weight: 900;*/
        @include transition((all ease-in-out .3s 0s, color ease-in-out 0s 0s));
    }
    &:hover:after {
        @include transform(translateX(5px));
    }

    &--back {
        padding-right: 0;
        padding-left: 24px;
    }
    &--back:after {
        content: '\27F5';
        right: auto;
        left: 0;
    }
    &--back:hover:after {
        @include transform(translateX(-5px));
    }
}



@import "custom/header.scss";

@import "custom/hero.scss";

@import "custom/imgTxt.scss";

@import "custom/collection.scss";

@import "custom/gallery.scss";

@import "custom/contact.scss";

@import "custom/collections.scss";

@import "custom/bottle.scss";

@import "custom/contactForm.scss";

@import "custom/gdpr.scss";

@import "custom/footer.scss";


@import "custom/shopForm.scss";

@import "custom/cart.scss";

@import "custom/orderForm.scss";

@import "custom/orderConfirm.scss";

@import "custom/ageConfirm.scss";



.grecaptcha-badge { 
    visibility: hidden; 
}
