
.hero {
    position: relative;
    display: flex;
    height: 200vh;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}

.hero__content {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding-bottom: 150px;
    }
    @media screen and (max-width: 425px) {
        padding-bottom: 130px;
    }
}

.hero__logo {
    pointer-events: none;
    position: absolute;
    width: auto;
    height: 626px;
    max-height: 80%;
    @include transform(scale((288/625)));
    @include transition-custom(opacity, .6s);
    opacity: 0;
    backface-visibility: hidden;

    &.loaded {
        opacity: 1;
    }
    .hero__content.loaded & {
        opacity: 1;
    }

    @media screen and (max-width: 768px) {
        max-width: 135vw;
        height: auto;
    }
}

.hero__heading {
    position: relative;
    line-height: (36/30);
    text-align: center;
    font-family: 'Nikola', serif;
    font-size: unquote("min(max(30px, calc(1.875rem + ((1vw - 3.2px) * 5.1724))), 60px)");
    color: #fff;
    opacity: 0;
    @include transition-custom(opacity, .15s);

    .hero__content.loaded & {
        opacity: 1;
    }

    @media screen and (min-width: 769px) {
        & br {
            display: none;
        }
    }
}

.hero__link {
    pointer-events: none;
    position: fixed;
    bottom: 115px;
    font-size: 16px;
    font-weight: 900;
    color: #fff;
    opacity: 0;
    @include transition-all();

    &.active {
        pointer-events: all;
        opacity: 1;
    }
    .hero__content.stop & {
        position: absolute;
        pointer-events: all;
        opacity: 1;
        @include transform(translateY(59px));
    }
}

.hero__mouse-wrapper {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: auto;
    height: 80px;
    width: 22px;
    @include transition-custom(opacity, .3s);

    .hero__content.stop & {
        opacity: 0;
    }
}

.hero__mouse {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 39px;
    border: 2px solid #fff;
    border-radius: 10px;

    &__wheel {
        position: relative;
        top: 7px;
        left: auto;
        height: 8px;
        width: 2px;
        background-color: #fff;

        @include animation-full(mouse-wheel 2.25s ease-out infinite);

        @include keyframes(mouse-wheel) {
            0% {
                opacity: 0;
                @include transform(scale(1,1));
                @include transform-origin(center top);
            }
            25% {
                opacity: 1;
                @include transform(scale(1,1));
                @include transform-origin(center top);

            }
            60% {
                opacity: 1;
                @include transform(scale(1,1));
                @include transform-origin(center bottom);

            }
            100% {
                opacity: 0;
                @include transform(scale(1,0) translateY(10px));
                @include transform-origin(center bottom);
            }
        };
    }
}