
.age-confirm {
    z-index: 1;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #000;

    opacity: 0;
    pointer-events: none;
    @include transition-custom(opacity);

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    @media screen and (max-width: 425px) {
        padding: 0 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 20px;
    }
}

.age-confirm__container {
    text-align: center;
    color: #fff;
    padding-top: var(--header-height);
}

.age-confirm__heading {
    font-weight: 700;
}

.age-confirm__buttons {
    margin: 50px auto;
    max-width: 330px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 425px) {
        margin: 40px auto;
        max-width: 300px;
    }
}

.age-confirm__btn {
    width: 140px;
    height: 40px;
    line-height: 39px;
    font-size: 15px;
    font-weight: 700;
    border: 1px solid #fff;
    border-radius: 40px;

    &:hover {
        color: #000;
        background-color: #fff;
    }

    @media screen and (max-width: 425px) {
        width: 130px;
    }
}

.age-confirm__para {
    line-height: (21/14);
    text-align: center;
    font-size: 14px;

    @media screen and (max-width: 425px) {
        font-size: 13px;
    }
}

.no-wrap {
    white-space: nowrap;
}