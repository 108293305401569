
.img-txt {

}

.img-txt__content {
    display: flex;
    align-items: center;

    &--reversed {
        flex-direction: row-reverse;
    }
}

.img-txt__img-wrapper {
    flex-shrink: 0;
    width: 49%;
    padding-top: 49%;
    background: #E2CBBC center no-repeat;
    background-size: cover;
    margin-bottom: -200px;

    &--centered {
        margin-bottom: 0;
    }
}

.img-txt__txt-wrapper {
    margin-bottom: -130px;
    width: 100%;

    &--centered {
        margin-bottom: 0;
    }
}

.img-txt__spacer {
    flex-shrink: 0;
    width: 100px;
}

.img-txt__heading {

}

.img-txt__para {
    line-height: (22/16);
    margin-top: 40px;
    font-size: 15px;
    font-weight: 500;

    @media screen and (max-width: 1080px) {
        & br {
            display: none;
        }
    }
}

.imt-text__link {
    margin-top: 40px;
}


@media screen and (max-width: 1024px) {

    .img-txt__spacer {
        width: 50px;
    }

    .img-txt__para,
    .imt-text__link {
        margin-top: 30px;
    }
}
@media screen and (min-width: 768px) {

    .img-txt__content {
        padding: 150px 0;

        &--first {
            padding-top: 80px;
        }
        &--last {
            padding-bottom: 250px;
        }
        &--centered {
            padding: 80px 0;
        }
    }
}
@media screen and (max-width: 767px) {

    .img-txt__content {
        flex-wrap: wrap;
    }

    .img-txt__img-wrapper {
        width: 100%;
        padding-top: 75%;
        margin-bottom: 0;
    }

    .img-txt__txt-wrapper {
        margin-bottom: 0;
        margin-top: 25px;
    }

    .img-txt__para,
    .imt-text__link {
        margin-top: 20px;
    }
}