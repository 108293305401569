
.contact-form-overlay {
    z-index: 555;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    pointer-events: none;
    opacity: 0;
    @include transition(opacity cubic-bezier(0.22, 0.61, 0.36, 1) .5s);

    &--active {
        pointer-events: all;
        opacity: 1;
    }
}

.contact-form-close {
    position: fixed;
    top: 45px;
    right: 45px;
    display: block;
    width: 30px;
    height: 30px;
    background: url("../web_images/ico_cf_close.svg") center no-repeat;
    background-size: contain;
    opacity: .5;

    &:hover {
        opacity: 1;
    }
}

.contact-form-overlay__inner {
    width: 100%;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    overflow-y: auto;

    padding: 0 45px;

    @media screen and (max-width: 768px) {
        padding: 0 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 0 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 20px;
    }
}

.contact-form {
    margin: 50px auto;
    max-width: 535px;
    width: 100%;
    @include transform(translateY(-50px));
    opacity: 0;
    @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .5s .3s);

    .contact-form-overlay--active & {
        @include transform(translateY(0));
        opacity: 1;
    }
}

.contact-form__heading {
    margin-bottom: 30px;
    line-height: (30/20);
    font-size: 20px;
    font-weight: 500;
}


/* vstupy formu */

.contact-form__input-wrapper {
    position: relative;
    font-size: 0;
    overflow: hidden;
    display: flex;
    width: 100%;

    & + & {
        margin-top: 8px;
    }

    &--select:after {
        z-index: 2;
        @include before-after(21px, auto, 11px, 10px);
        pointer-events: none;
        right: 18px;
        background: url("../web_images/ico_select_arrow.svg") center no-repeat;
        background-size: contain;
    }
}

.contact-form__input-wrapper__spacer {
    flex-shrink: 0;
    width: 10px;
}

.contact-form__input,
.contact-form__textarea {
    padding: 15px 18px 14px;
    width: 100%;
    font-family: "Cera", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: 1px solid transparent;

    &:hover {
        border-color: #d9d9d9;
    }
    &:focus {
        border-color: #e6e6e6;
    }

    @include placeholder() {
        color: #808080;
    };
}
.contact-form__textarea {
    line-height: 1.5;
    padding: 15px 18px;
    resize: none;
}
.contact-form__input--select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


/* validace */

.errors-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: initial;
    padding: 4px 7px 5px;
    font-size: 12px;
    color: #fff;
    //background-color: #f0636a;
    background-color: #e2535a;
    opacity: 0;
    pointer-events: none;
    @include transform(translateY(-5px));
    @include transition(none);
}
.not-validated + .errors-container {
    opacity: 1;
    @include transform(translateY(0px));
    @include transition(all 0.2s ease-out);
}

.not-validated + .contact-form__gdpr-text {
    color: #d16065;
}

.contact-form .not-validated {
    border-color: #fd747a;

    &:focus {
        border-color: #fd747a;
    }
    &:hover {
        border-color: #d16065;
    }
}


/* gdpr & req hint */

.contact-form__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    font-size: 12px;
    font-weight: 500;
    color: #808080;
}

.contact-form__gdpr-label {
    display: flex;
    align-items: center;
}

.contact-form__gdpr-checkbox {
    margin-right: 5px;
}

.contact-form__gdpr-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.contact-form__req {

}


/* zapati formu */

.contact-form__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 25px;
}

.contact-form__phone {
    line-height: (23/16);
    font-size: 16px;
    font-weight: 700;
}

.contact-form__phone-link {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: auto;
        right: 0;
        display: block;
        width: 0;
        height: 1px;
        background: #000;
        @include transition-all();
    }
    &:hover:before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

.contact-form__submit-wrapper {
    position: relative;

    &:after {
        pointer-events: none;
        z-index: 2;
        @include before-after(0, auto, 19px);
        content: '\27F6';
        right: 0;
        font-family: 'Cera', serif;
        font-size: 16px;
        font-weight: 900;
        @include transition((all ease-in-out .3s 0s, color ease-in-out 0s 0s));
    }
    &:hover:after {
        @include transform(translateX(5px));
    }
}

.contact-form__submit {
    display: block;
    padding-right: 24px;
    font-family: 'Cera', sans-serif;
    background-color: transparent;
}


/* recaptcha */

.contact-form__recaptcha {
    margin-top: 25px;
    //text-align: center;
    font-size: 12px;
    color: #808080;
}


@media screen and (max-width: 700px) {

    .contact-form-close {
        top: 30px;
        right: 30px;
    }

    .contact-form__heading {
        font-size: 18px;
        padding-right: 70px;
    }
}
@media screen and (min-width: 601px) {

    .contact-form-close--mobile {
        display: none;
    }
}
@media screen and (max-width: 600px) {

    .contact-form-close--desktop {
        display: none;
    }
    .contact-form-close--mobile {
        position: absolute;
        top: 11px;
        right: 0;
    }

    .contact-form__heading {
        position: relative;
    }

    .contact-form {
        margin: 35px auto;
    }

    .contact-form__input-wrapper {
        flex-direction: column;
    }

    .contact-form__input-wrapper__spacer {
        height: 8px;
    }
}
@media screen and (max-width: 500px) {

    .contact-form__heading br {
        display: none;
    }
}
@media screen and (max-width: 425px) {

    .contact-form__heading {
        margin-bottom: 20px;
    }

    .contact-form {
        margin: 25px auto;
    }

    .contact-form__bottom {
        flex-direction: column;
    }

    .contact-form__req {
        margin-top: 20px;
    }
}
@media screen and (max-width: 375px) {

    .contact-form__heading {
        font-size: 16px;
    }

    .contact-form {
        margin: 20px auto;
    }
}