
.gallery {

}

.gallery__content {
    text-align: center;
    max-width: 1435px;
}

.gallery__heading {

}

.gallery__list {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
}

.gallery__item-placeholder {
    padding-top: 100%;
    background-color: #fff;

    .gallery__list--loaded & {
        display: none;
    }

    @include animation-full(pulsing 3s ease-in-out infinite);

    @media screen and (min-width: 1151px) {

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8) {
            @include animation-full(pulsing 3s 1s ease-in-out infinite);
        }
    }
    @media screen and (max-width: 1150px) {

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(9) {
            @include animation-full(pulsing 3s 1s ease-in-out infinite);
        }
    }
    @media screen and (max-width: 900px) {

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(8) {
            @include animation-full(pulsing 3s 1s ease-in-out infinite);
        }
    }
    @media screen and (max-width: 570px) {

        &:nth-child(odd) {
            @include animation-full(pulsing 3s 1s ease-in-out infinite);
        }
    }

    @include keyframes(pulsing) {
        from { opacity: 1; }
        50% { opacity: .33; }
        to { opacity: 1; }
    }
}

.gallery__item-wrapper {
    position: relative;
    padding-top: 100%;
    @include transition-custom(opacity);
    opacity: 0;

    .gallery__list--loaded & {
        opacity: 1;
    }
}

.gallery__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center no-repeat;
    background-size: cover;

    &:after {
        @include before-after();
        background-color: rgba(0,0,0, .56);
        opacity: 0;
        @include transition-custom(opacity);
    }

    .gallery__item-wrapper:hover &:after {
        opacity: 1;
    }
}

.gallery__text {
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    max-width: 50%;
    line-height: (20/15);
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    opacity: 0;
    @include transition-custom(opacity);

    .gallery__item-wrapper:hover & {
        opacity: 1;
    }
}

.gallery__link {
    position: absolute;
    bottom: 15px;
    right: 14px;
    display: block;
    width: 20px;
    height: 20px;
    background: url("../web_images/ico_ig_w.svg") center no-repeat;
    background-size: contain;
    opacity: 0;
    @include transition(all ease-in-out .3s 0s);

    .gallery__item-wrapper:hover & {
        opacity: 1;
        @include transition((all ease-in-out .3s 0s, opacity ease-in-out .3s .3s));
    }

    &:hover {
        @include transform(scale(1.2));
    }
}

.gallery__btn {
    margin-top: 50px;
}


@media screen and (min-width: 1151px) {

    .gallery__item-placeholder:nth-child(9),
    .gallery__item-wrapper:nth-child(18) {
        display: none;
    }
}
@media screen and (max-width: 900px) {

    .gallery__item-placeholder:nth-child(9),
    .gallery__item-wrapper:nth-child(18) {
        display: none;
    }
}
@media screen and (max-width: 1150px) {

    .gallery__list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 900px) {

    .gallery__list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 768px) {

    .gallery__list,
    .gallery__btn {
        margin-top: 45px;
    }
}
@media screen and (max-width: 570px) {

    .gallery__list {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 375px) {

    .gallery__list,
    .gallery__btn {
        margin-top: 40px;
    }
}