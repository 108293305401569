
.footer {
    &--gdpr {
        border-top: 1px solid #fff;
    }
}

.footer__content {
    display: flex;
    align-items: center;
    padding: 40px 0;
}

.footer__inter-links {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.footer__inter-link {
    position: relative;
    font-size: 15px;
    font-weight: 500;

    &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: auto;
        right: 0;
        display: block;
        width: 0;
        height: 1px;
        background: #000;
        @include transition-all();
    }
    &:hover:before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

.footer__social-links {
    flex-shrink: 0;
    display: flex;
    margin-left: auto;
}

.footer__social-link {
    width: 20px;
    height: 20px;
    background: center no-repeat;
    background-size: contain;

    & + & {
        margin-left: 10px;
    }

    &--fb {
        background-image: url("../web_images/ico_fb.svg");
    }
    &--ig {
        background-image: url("../web_images/ico_ig_b.svg");
    }

    &:hover {
        @include transform(scale(1.2));
    }
}


@media screen and (max-width: 1024px) {

    .footer__content {
        padding: 35px 0;
    }
}
@media screen and (max-width: 1023px) {

    .footer__inter-links {
        width: 85%;
    }
}
@media screen and (max-width: 767px) {

    .footer__content {
        padding: 30px 0;
    }

    .footer__inter-links {
        display: none;
    }

    .footer__social-links {
        margin-right: auto;
    }

    .footer__social-link {
        width: 25px;
        height: 25px;

        & + & {
            margin-left: 15px;
        }
    }
}
@media screen and (max-width: 425px) {

    .footer__content {
        padding: 25px 0;
    }
}