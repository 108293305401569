
.bottle {
    position: relative;
    padding-top: var(--header-height);
}

.bottle__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: center;
}


/* obrazek / ovlast vlevo */

.bottle__img-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.bottle__img {
    width: 100%;
    padding-top: 640px;
    background: center no-repeat;
    background-size: contain;

    @media screen and (max-width: 600px) {
        padding-top: 120.755%;
    }
}

.bottle__back-wrapper {
    display: flex;
    justify-content: center;
}

.bottle__back {
    color: #fff;

    &--desktop {
        margin-top: 20px;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &--mobile {
        @media screen and (min-width: 768px) {
            display: none;
        }
        @media screen and (max-width: 767px) {
            margin-top: 60px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 50px;
        }
        @media screen and (max-width: 375px) {
            margin-top: 45px;
        }
    }
    .content-wrapper--beige & {
        color: #000;
    }
}


/* text / ovlast vpravo */

.bottle__text-wrapper {
    color: #fff;
    font-size: 14px;
    line-height: (21/14);

    .content-wrapper--beige & {
        color: #000;
    }
}

.bottle__text-inner-wrapper {
    max-width: 345px;
}

.bottle__divider {
    margin: 15px 0;
    height: 1px;
    background-color: #333333;

    .content-wrapper--beige & {
        background-color: #afa8a4;
    }
}

.bottle__heading {
    position: relative;
    width: 80%;
    line-height: (42/35);
    font-family: 'Nikola', serif;
    font-size: 35px;
}

.bottle__base-label {
    margin-top: 40px;
}

.bottles__sugar {
    position: relative;
    padding-left: 22px;
}

.bottles__sugar-ico {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 17px;
    background: center no-repeat;
    background-size: contain;

    &--dry {
        background-image: url("../web_images/ico_dry.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_dry_w.svg");
        }
    }
    &--semidry {
        background-image: url("../web_images/ico_semidry.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_semidry_w.svg");
        }
    }
    &--sweet {
        background-image: url("../web_images/ico_sweet.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_sweet_w.svg");
        }
    }
    &--semisweet {
        background-image: url("../web_images/ico_semisweet.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_semisweet_w.svg");
        }
    }
}

.bottle__desc {

}

.bottle__characteristic {

}

.bottle__characteristic-heading {
    font-size: 15px;
    font-weight: 700;
}

.bottle__characteristic-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.bottle__price-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}

.bottle__price {
    width: 50%;
    line-height: 1;
    padding-left: 5px;
    text-align: left;
    font-size: 20px;
}

.bottle__footer {

}

.bottle__buy {
    padding: 0 45px 0 23px;
    line-height: 37px;
    color: #000;
    background-color: #fff;

    &:after {
        right: 20px;
    }

    .content-wrapper--beige & {
        color: #fff;
        background-color: #000;
    }
}

.bottle__min-order {

}


@media screen and (max-width: 768px) {

    .bottle__base-label {
        margin-top: 35px;
    }
}
@media screen and (max-width: 767px) {

    .bottle__content {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;
        padding-top: 25px;
    }

    .bottle__text-inner-wrapper {
        margin: 0 auto;
    }
}
@media screen and (max-width: 425px) {

    .bottle__content {
        grid-gap: 25px;
        padding-top: 15px;
    }

    .bottle__heading {
        font-size: 30px;
    }

    .bottle__base-label {
        margin-top: 20px;
    }

    .bottle__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex-wrap: wrap;
    }

    .bottle__loc {
        margin-right: 0;
        margin-bottom: 15px;
    }
}