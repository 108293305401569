
.header {
    z-index: 555;
    position: fixed;
    top: 0;
    left: 0;
    height: var(--header-height);
    width: 100%;
    background-color: transparent;

    @include transition(background-color .3s ease-out);
}
.header.active,
.header.hamburger-active,
.header.always-active {
    background-color: #000;
    background-color: rgba(0,0,0, .95);
}

.header__container {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: $max-layout-width;
    margin: 0 auto;
}


@media screen and (min-width: 1024px) {

    .menu {
        display: flex;
        margin-left: auto;
    }

    .menu__item {
        position: relative;
        line-height: var(--header-height);
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        white-space: nowrap;

        & + & {
            margin-left: 35px;
        }
    }

    .header__logo {
        position: relative;
        flex-shrink: 0;
        height: 100%;
        width: 181px;
        background: url("../web_images/logo_desktop.svg") center left no-repeat;
        background-size: contain;
    }

    .menu__link {
        display: block;

        &--order {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    .menu__link-wrapper {
        position: relative;

        &--order {
            line-height: 40px;
            padding: 0 25px;
            border: 1px solid #fff;
            border-radius: 50px;
            @include transition-all();

            .menu__link:hover & {
                color: #000;
                background-color: #fff;
            }
        }
        &:not(&--order):before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: auto;
            right: 0;
            display: block;
            width: 0;
            height: 1px;
            background: #fff;
            @include transition-all();
        }
        .menu__link:hover &:before {
            left: 0;
            right: auto;
            width: 100%;
        }
    }


    /* Hamburger menu */

    .hamburger-menu {
        display: none;
    }
}
@media screen and (max-width: 1023px) {

    .header__logo {
        flex-shrink: 0;
        height: 100%;
        width: 95px;
        margin-top: -5px;
        background: url("../web_images/logo.svg") center left no-repeat;
        background-size: contain;
    }

    .menu {
        position: fixed;
        bottom: 0;
        left: 0;
        top: var(--header-height);
        width: 100%;
        text-align: center;
        background-color: rgba(0,0,0, .95);
        padding: 30px 45px 50px;

        @media screen and (max-width: 768px) {
            padding-left: 35px;
            padding-right: 35px;
        }
        @media screen and (max-width: 425px) {
            padding-left: 25px;
            padding-right: 25px;
        }
        @media screen and (max-width: 375px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media screen and (min-height: 480px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 45px var(--header-height);
        }

        list-style: none;

        pointer-events: none;
        opacity: 0;
        height: 0;
        overflow: hidden;

        @include transition((opacity .3s ease-out 0s, height 0s ease-out .3s));
    }
    .menu.active {
        pointer-events: auto;
        opacity: 1;
        height: calc(100% - var(--header-height));
        overflow-y: auto;

        @include transition((opacity .3s ease-out 0s, height 0s ease-out 0s));
    }

    .menu__item {
        position: relative;
        padding: 5px 0;
        opacity: 0;
        line-height: 38px;
        font-size: 15px;
        font-weight: 500;
        color: #fff;

        @include transform(translateY(-50px));
        @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .4s);
        @include transition-delay(0s);
    }
    .menu.active .menu__item {
        opacity: 1;
        @include transform(translateY(0));
        @include transition-delay(.15s);
    }

    .menu__link {
        display: inline-block;
    }

    .menu__link-wrapper {
        position: relative;

        &--order {
            display: block;
            margin-top: 10px;
            line-height: 40px;
            padding: 0 17px;
            border: 1px solid #fff;
            border-radius: 50px;
            @include transition-all();

            .menu__link:hover & {
                color: #000;
                background-color: #fff;
            }
        }
        &:not(&--order):before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: auto;
            right: 0;
            display: block;
            width: 0;
            height: 1px;
            background: #fff;
            @include transition-all();
        }
        .menu__link:hover &:before {
            left: 0;
            right: auto;
            width: 100%;
        }
    }


    /* Hamburger menu */

    $hm-before-top: 6px;
    $hm-after-top: $hm-before-top*2;
    $hm-height: 1px;
    $hm-width: 18px;
    $hm-offset-top: -($hm-after-top + $hm-height)/2;
    $hm-offset-left: -($hm-width/2);

    .hamburger-menu {
        position: relative;
        z-index: 10000;
        width: 40px;
        height: 40px;
        margin-left: 20px;
        margin-right: -10px;

        @media screen and (max-width: 375px) {
            margin-left: 15px;
        }
    }

    .hamburger-menu .breadroll {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: $hm-offset-top 0 0 $hm-offset-left;
    }

    .hamburger-menu .breadroll .beef,
    .hamburger-menu .breadroll .beef::before,
    .hamburger-menu .breadroll .beef::after {
        display: inline-block;
        position: absolute;
        width: $hm-width;
        height: $hm-height;

        background-color: #fff;

        @include transition-all(.25s);
    }
    .hamburger-menu .breadroll .beef::before {
        top: $hm-before-top;
        content: "";
        opacity: 1;
    }
    .hamburger-menu .breadroll .beef::after {
        top: $hm-after-top;
        content: "";
    }
    .hamburger-menu.active .breadroll .beef {
        @include transform(translate3d(0px, $hm-before-top, 0px) rotate(45deg));
    }
    .hamburger-menu.active .breadroll .beef::before {
        opacity: 0;
    }
    .hamburger-menu.active .breadroll .beef::after {
        @include transform(translate3d(0px, -$hm-after-top, 0px) rotate(-90deg));
    }
}