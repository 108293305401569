
.order-notification {
    padding-top: var(--header-height);
}

.order-notification__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 500px;
}

.order-notification__ico {
    width: 63px;
    height: 63px;
    background: center no-repeat;
    background-size: contain;

    &--success-order,
    &--success-payment {
        background-image: url("../web_images/ico_submit_succeeded.svg");
    }

    @include transform(scale(0));
    @include animation-full(pop forwards .5s .3s cubic-bezier(0.22, 0.61, 0.36, 1));

    @include keyframes(pop) {
        0% {
            @include transform(scale(0));
        }
        50% {
            @include transform(scale(1.1));
        }
        100% {
            @include transform(scale(1));
        }
    };
}

.order-notification__heading {
    margin-top: 25px;
    line-height: (51/35);
    font-weight: 700;
}

.order-notification__para {
    margin-top: 20px;
    line-height: 1.375;
    font-size: 15px;
    font-weight: 500;
}

$btn-height: 40px;

.order-notification__back-btn {
    margin-top: 40px;
    text-align: center;
    min-width: 185px;
    padding: 0 17px;
    height: $btn-height;
    line-height: $btn-height;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    border-radius: $btn-height;
    background-color: #debb98;

    &:hover {
        background-color: #d6ae86;
    }
}