
.shop-form {

}

.shop-form__wrapper {
    position: relative;
}

.shop-form__errors-container {
    top: -25px;
    right: auto;
    left: 0;
    padding: 0;
    opacity: 1;
    border-radius: 5px;
}

.shop-form__errors-container .validation-nok {
    padding: 4px 7px 5px;
}

.shop-form__inner-wrapper {
    display: flex;
}

.shop-form__amount-wrapper {
    display: flex;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
    background-color: #fff;
}

.shop-form__amount-inner-wrapper {
    position: relative;
}

/* vstup pro zadani mnozstvi */

.shop-form__amount {
    height: 40px;
    width: 60px;
    padding: 0 25px 0 10px;
    text-align: center;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 14px;
    background-color: #F2F2F2;

    /* Mozzila */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.shop-form__amount-label {
    padding: 0 14px;
    font-size: 14px;
    color: #000;
}

/* tlacitka pro +- mnozstvi */

.shop-form__btn {
    position: absolute;
    right: 0;
    display: block;
    width: 25px;
    height: 50%;

    &:after {
        @include before-after();
        background: url("../web_images/ico_basket_add_arrow.svg") center no-repeat;
        background-size: 9px 5px;
    }

    &--plus {
        top: 0;
    }
    &--minus {
        bottom: 0;

        &:after {
            @include transform(rotate(180deg));
        }
    }

    &:hover {
        background-color: #e6e6e6;
    }
}

/* tlacitko pro vlozeni do kosiku */

$btn-height: 40px;
$submitted-transition-timing: .3s;

.shop-form__submit-wrapper {
    position: relative;
    margin-left: 15px;

    @media screen and (max-width: 375px) {
        margin-left: 10px;
    }
}

.shop-form__submit-input,
.shop-form__submit,
.shop-form__proceeding,
.shop-form__submitted {
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #000;

    @media screen and (max-width: 350px) {
        font-size: 15px;
    }
}

.shop-form__submit-input {
    text-align: center;
    width: 185px;
    height: $btn-height;
    line-height: $btn-height;
    background-color: #DEBB98;
    border-radius: $btn-height;

    @media screen and (max-width: 350px) {
        width: 169px;
    }
}

.shop-form__submit,
.shop-form__proceeding,
.shop-form__submitted {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: #DEBB98;
    border-radius: $btn-height;

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}

.shop-form__submit {
    opacity: 1;
    @include transition-custom(background-color, .3s);

    .shop-form__submit-wrapper:hover & {
        background-color: #d6ae86;
    }
}

.shop-form__submit-label {
    pointer-events: none;
    padding-right: 25px;
    background: url("../web_images/ico_basket.svg") center right no-repeat;
    background-size: 20px 18px;

    @media screen and (max-width: 350px) {
        padding-right: 20px;
        background-size: 18px 16px;
    }
}

.shop-form__proceeding {
    @include transition(none);

    &.active {
        @include transition-custom(opacity, .15s);
    }

    &:after {
        @include before-after(auto, auto, 20px, 20px);
        background: url("../web_images/ico_basket_add_proceeding.svg") center no-repeat;
        background-size: contain;

        @include animation-full(spin infinite linear 2.5s);
        @include keyframes(spin) {
            from {@include transform(rotate(0deg));}
            to {@include transform(rotate(360deg));}
        }
    }
}

.shop-form__submitted {
    @include transition-custom(opacity, $submitted-transition-timing);
}

.shop-form__submitted-inner-wrapper {
    position: relative;
    max-width: 0;
    padding-right: 35px;
    overflow: hidden;
    white-space: nowrap;
    @include transition(max-width 0s $submitted-transition-timing);

    .shop-form__submitted.active & {
        max-width: 100px;
        @include transition(max-width .6s .2s cubic-bezier(0.22, 0.61, 0.36, 1));
    }

    &:after {
        @include before-after(1px, auto, 20px, 20px);
        right: 7px;
        background: url("../web_images/ico_basket_add_succeeded.svg") center no-repeat;
        background-size: contain;
        @include transform(rotate(0deg));
    }
}

.shop-form__submitted-label {
    pointer-events: none;
    opacity: 0;
    @include transition(opacity 0s $submitted-transition-timing);

    .shop-form__submitted.active & {
        @include transition(opacity .3s .45s ease-in-out);
        opacity: 1;
    }
}