
.gdpr {
    padding-top: var(--header-height);
}

.gdpr strong {
    font-weight: 700;
}

.gdpr__content {
    margin: 0 auto;
    max-width: 705px;
    font-size: 14px;
}

.gdpr__heading {
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;

    @media screen and (max-width: 768px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 425px) {
        margin-bottom: 30px;
    }
    @media screen and (min-width: 450px) {
        & br {
            display: none;
        }
    }
}

.gdpr__para {
    line-height: (21/14);

    & + & {
        margin-top: 15px;

        @media screen and (max-width: 425px) {
            margin-top: 10px;
        }
    }
    .gdpr__list + & {
        margin-top: 15px;

        @media screen and (max-width: 425px) {
            margin-top: 10px;
        }
    }
}

.gdpr__list {
    line-height: (21/14);
    list-style-type: disc;
    padding-left: 18px;

    .gdpr__para + & {
        margin-top: 15px;

        @media screen and (max-width: 425px) {
            margin-top: 10px;
        }
    }
    &--nested {
        margin: 10px 0;
    }
    &--alpha {
        list-style-type: lower-alpha;
    }
    &--decimal {
        list-style-type: decimal;
    }
}

.gdpr__list-item {
    padding-left: 10px;

    @media screen and (max-width: 425px) {
        padding-left: 5px;
    }
}

.gdpr__heading2 {
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;

    @media screen and (max-width: 425px) {
        margin-top: 25px;
        margin-bottom: 15px;
    }
}