
.cart {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 25px;

    @media screen and (max-width: 1023px) {
        margin-left: auto;
    }
}

.cart__btn {
    position: relative;
    width: 24px;
    height: 25px;
    background: url("../web_images/ico_basket_white.svg") center no-repeat;
    background-size: contain;

    &:hover {

    }

    @media screen and (max-width: 1023px) {
        background-size: 19px;
    }
}

.cart__counter {
    pointer-events: none;
    position: absolute;
    right: -4px;
    top: -2px;
    display: block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
    color: #000;
    background-color: #DEBB98;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include transition(all .3s cubic-bezier(0.22, 0.61, 0.36, 1));

    &.active {
        @include transform(scale(1.5));
    }
}

.cart__content {
    position: absolute;
    top: var(--header-height);
    left: -137px;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 16px 20px 23px 20px;
    background-color: #F2F2F2;
    line-height: normal;
    color: #000;
    opacity: 0;
    pointer-events: none;
    @include transform(translateY(10px));
    @include transition(all .3s cubic-bezier(0.22, 0.61, 0.36, 1));

    &.active {
        opacity: 1;
        pointer-events: all;
        @include transform(translateY(0));
    }

    &:before {
        @include before-after(-8px, 50%, 0, 0);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent #F2F2F2 transparent;

        @media screen and (min-width: 1401px) {
            @include transform(translateX(-50%));
        }
        @media screen and (max-width: 1400px) {
            left: auto;
            right: 17px;
        }
        @media screen and (max-width: 1023px) {
            right: 51px;
        }
        @media screen and (max-width: 375px) {
            right: 46px;
        }
    }

    @media screen and (max-width: 1400px) {
        left: -260px
    }
    @media screen and (max-width: 1023px) {
        left: -227px;
    }
    @media screen and (max-width: 375px) {
        left: -232px;
    }
    @media screen and (max-width: 340px) {
        left: -212px;
        width: 280px;
    }
}

.cart__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__heading {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .24px;
    text-transform: uppercase;
}

.cart__close {
    width: 10px;
    height: 10px;
    background: url("../web_images/ico_close_basket.svg") center no-repeat;
    background-size: contain;
}

.cart__items {
    margin-top: 13px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.cart__item {
    display: grid;
    grid-template-columns: 45px 1fr auto;
    align-items: center;
    padding: 7px 0;
    font-size: 12px;

    & + & {
        border-top: 1px solid #E5E5E5;
    }
}

.cart__item__img-wrapper {
    flex-shrink: 0;
    width: 35px;
    height: 55px;
    background: center no-repeat;
    background-size: contain;
}

.cart__item__left-wrapper {
    min-width: 0;
    padding-right: 15px;
}

.cart__item__name {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart__item__desc {
    margin-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart__item__count {
    margin-top: 2px;
    font-weight: 700;
}

.cart__item__right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    margin-left: auto;
}

.cart__item__remove {
    width: 16px;
    height: 16px;
    background: #fff url("../web_images/ico_remove_baske_item.svg") center no-repeat;
    background-size: 6px 6px;
    border-radius: 50%;

    &:hover {
        background-color: #e6e6e6;
    }

    @media screen and (max-width: 1023px) {
        width: 21px;
        height: 21px;
        background-size: 7px 7px;
    }
}

.cart__item__price {
    margin-left: auto;
    font-weight: 700;
}

.cart__empty {
    padding: 15px 0;
    font-size: 12px;
}

$btn-height: 40px;

.cart__proceed {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: $btn-height;
    margin-top: 23px;
    margin-left: auto;
    font-size: 16px;
    font-weight: 500;
    background-color: #DEBB98;
    border-radius: $btn-height;

    &:hover {
        background-color: #d6ae86;
    }

    @media screen and (max-width: 350px) {
        font-size: 15px;
    }
    @media screen and (max-width: 350px) {
        width: 169px;
    }
}

/*
.cart__proceed-label {
    padding-right: 25px;
    background: url("../web_images/ico_basket.svg") center right no-repeat;
    background-size: 20px 18px;

    @media screen and (max-width: 350px) {
        padding-right: 20px;
        background-size: 18px 16px;
    }
}*/
