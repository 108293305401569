
.contact {

}

.contact__content {
    display: flex;
    align-items: center;
}

.contact__heading {
    margin-bottom: 30px;
}

.contact__columns-wrapper {
    width: 51%;
    padding-right: 150px;
}

.contact__columns {
    display: grid;
    grid-template-columns: 1fr 170px;

    & & {
        grid-template-columns: repeat(2, 1fr);
    }
    & + & {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #000;
    }
}

.contact__column {

}

.contact__link {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: auto;
        right: 0;
        display: block;
        width: 0;
        height: 1px;
        background: #000;
        @include transition-all();
    }
    &:hover:before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

.contact__row {
    line-height: 22px;
    height: 22px;
    font-size: 15px;
    font-weight: 500;
}

.contact__map-wrapper {
    position: relative;
    width: 49%;
    padding-top: 49%;
    background-color: #f2f2f2;
}

.contact__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1100px) {

    .contact__columns-wrapper {
        padding-right: 60px;
    }
}
@media screen and (max-width: 900px) {

    .contact__content {
        flex-wrap: wrap;
    }

    .contact__columns-wrapper {
        padding-right: 0;
        width: 100%;
        max-width: 400px;
    }

    .contact__map-wrapper {
        width: 100%;
        padding-top: 100%;
        margin-top: 35px;
    }
}
@media screen and (max-width: 425px) {

    .contact__columns {
        grid-template-columns: 1fr;

        & & {
            margin-top: 22px;
        }
        /*& + & {
            margin-top: 25px;
            padding-top: 25px;
        }*/
    }

    .contact__column--mobile-mt {
        margin-top: 22px;
    }
}
