
.order-form__content {
    padding-top: 55px;
    max-width: 710px;

    @media screen and (max-width: 425px) {
        padding-top: 45px;
    }
    @media screen and (max-width: 375px) {
        padding-top: 40px;
    }
}

.order-form__empty-cart {
    max-width: 400px;
    margin-top: 30px;
    line-height: 1.375;
    font-size: 15px;
    font-weight: 500;
}


/* kroky */

.order-form__steps-wrapper {
    padding-top: var(--header-height);
    background-color: #F2F2F2;
}

.order-form__steps {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 710px;
    height: 50px;
}

.order-form__step {
    position: relative;

    &:after {
        @include before-after(auto);
        bottom: 0;
        height: 3px;
        background-color: #000;
        pointer-events: none;
        @include transform-origin(bottom center);
        @include transform(scaleY(0));
        @include transition-all();
    }
    &:hover:after,
    &.active:after {
        @include transform(scaleY(1));
    }

    @media screen and (max-width: 640px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.order-form__step-btn {
    height: 100%;
    color: #000;
    background: transparent;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;

    @media screen and (max-width: 640px) {
        .order-form__step:not(.active) & {
            width: 45px;
            font-size: 0;
        }
    }
}

.order-form__step-label {
    @media screen and (min-width: 641px) {
        display: none;
    }
    @media screen and (max-width: 640px) {
        position: absolute;
        font-size: 15px;
        font-weight: 500;
        pointer-events: none;

        .order-form__step.active & {
            display: none;
        }
    }
}


/* spolecne casti  */

.order-form__hidden {
    display: none;
}

.order-form__heading {
    margin-top: 45px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: 425px) {
        margin-top: 35px;
    }

    &:first-child {
        margin-top: 0;
    }
}

.order-form__bottom {
    display: flex;
    margin-top: 50px;

    @media screen and (min-width: 601px) {
        align-items: center;
        justify-content: space-between;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
        margin-top: 30px;
    }
}


/* polozky v kosiku */

.order-form__cart-heading {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 600px) {
        margin-bottom: 15px;
    }
    @media screen and (max-width: 540px) {
        display: none;
    }
}

.order-form__cart-caption {
    width: 25%;
    font-size: 15px;
    font-weight: 500;

    @media screen and (max-width: 600px) {
        flex-shrink: 0;
        width: 105px;
    }

    &:first-child {
        min-width: 0;
        width: 50%;

        @media screen and (max-width: 600px) {
            flex-shrink: 1;
            width: 100%;
        }
    }
}

.order-form__cart-item {
    display: flex;
    font-size: 15px;
    background-color: #F2F2F2;

    @media screen and (max-width: 540px) {
        flex-wrap: wrap;
    }

    & + & {
        margin-top: 4px;
    }
}

.order-form__cart-item__column {
    display: flex;
    align-items: center;
    width: 25%;
    min-width: 0;

    @media screen and (max-width: 600px) {
        flex-shrink: 0;
        width: 105px;
    }
    @media screen and (max-width: 540px) {
        width: 50%;
        padding: 0 17px 17px;
    }

    &:first-child {
        width: 50%;
        padding: 20px 25px;

        @media screen and (max-width: 600px) {
            flex-shrink: 1;
            width: 100%;
            padding: 10px 20px;
        }
        @media screen and (max-width: 540px) {
            padding: 17px;
        }
    }
    &--price {
        justify-content: space-between;
        padding-right: 25px;

        @media screen and (max-width: 600px) {
            padding-right: 20px;
        }
    }
    @media screen and (max-width: 540px) {
        &--sum-amount,
        &--sum-price {
            display: none;
        }
    }
}

.order-form__cart-item__photo {
    flex-shrink: 0;
    width: 30px;
    height: 80px;
    background: center no-repeat;
    background-size: contain;

    @media screen and (max-width: 600px) {
        width: 22px;
        height: 75px;
    }
    @media screen and (max-width: 540px) {
        width: 16px;
        height: 40px;
    }
}

.order-form__cart-item__text {
    margin-left: 25px;
    min-width: 0;

    @media screen and (max-width: 600px) {
        margin-left: 20px;
    }
    @media screen and (max-width: 540px) {
        margin-left: 15px;
        width: 100%;
    }
}

.order-form__cart-item__name {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-form__cart-item__desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-form__cart-item__sum {

    @media screen and (min-width: 541px) {
        display: none;
    }
    @media screen and (max-width: 540px) {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 700;
    }
}


/* vstup pro zadani mnozstvi */

.order-form__cart-item__amount-wrapper {
    position: relative;
    width: 80px;
    font-weight: 700;

    @media screen and (max-width: 540px) {
        width: 100%;
    }
}

.order-form__cart-item__amount {
    height: 40px;
    width: 100%;
    padding: 0 25px 0 15px;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 14px;
    background-color: #fff;

    /* Mozzila */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.order-form__cart-item__amount-btn {
    position: absolute;
    right: 0;
    display: block;
    width: 25px;
    height: 50%;

    &:after {
        @include before-after();
        background: url("../web_images/ico_basket_add_arrow.svg") center no-repeat;
        background-size: 9px 5px;
    }

    &--plus {
        top: 0;
    }
    &--minus {
        bottom: 0;

        &:after {
            @include transform(rotate(180deg));
        }
    }

    &:hover {
        background-color: #e6e6e6;
    }
}


/* cena a odstraneni polozky */

.order-form__cart-item__price {
    font-weight: 700;
}

.order-form__cart-item__remove {
    width: 22px;
    height: 22px;
    background: #fff url("../web_images/ico_remove_baske_item.svg") center no-repeat;
    background-size: 8px 8px;
    border-radius: 50%;

    &:hover {
        background-color: #e6e6e6;
    }
}


/* tlacitka */

$btn-height: 40px;

.order-form__back-btn,
.order-form__next-btn {
    text-align: center;
    min-width: 185px;
    padding: 0 17px;
    height: $btn-height;
    line-height: $btn-height;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #000;
    border-radius: $btn-height;
}
.order-form__back-btn {
    font-weight: 500;
    background-color: #F2F2F2;
    &:hover {
        background-color: #ebebeb;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}
.order-form__next-btn {
    font-weight: 700;
    background-color: #debb98;
    &:hover {
        background-color: #d6ae86;
    }

    @media screen and (min-width: 601px) {
        margin-left: auto;
    }
    @media screen and (max-width: 600px) {
        margin-top: 20px;
    }
}


/* celkova cena */

.order-form__total-price-wrapper {
    display: flex;
    margin-top: 30px;

    @media screen and (max-width: 425px) {
        margin-top: 20px;
    }
}

.order-form__total-price {
    margin-left: auto;
    line-height: 1.1;

    @media screen and (min-width: 601px) {
        &--left {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 600px) {
        text-align: center;
        margin-right: auto;
    }
}

.order-form__total-price__label {
    font-size: 15px;
}

.order-form__total-price__price {
    font-size: 25px;
    font-weight: 700;
}


/* vstupy pro kontaktni info */

.order-form__contacts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px 16px;

    @media screen and (max-width: 600px) {
        grid-gap: 15px 13px;
    }
    @media screen and (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        grid-template-areas:
            "first"
            "second"
            "third"
            "fourth";

        & .errors-container-parent:nth-child(1) {
            grid-area: first;
        }
        & .errors-container-parent:nth-child(2) {
            grid-area: second;
        }
        & .errors-container-parent:nth-child(3) {
            grid-area: fourth;
        }
        & .errors-container-parent:nth-child(4) {
            grid-area: third;
        }
    }
}

.order-form__contact-input,
.order-form__textarea {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-family: 'Cera', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    background-color: #F2F2F2;

    @include placeholder() {
        color: #808080;
    };

    &:hover {
        background-color: #ebebeb;
    }
}
.order-form__textarea {
    height: auto;
}


/* validace */

.order-form__errors-container-parent {
    position: relative;

    &--under {
        margin-top: 25px;
        & + & {
            margin-top: 1px;
        }
    }
}

.order-form__errors-container {
    top: -5px;
    left: auto;
    right: -5px;
    padding: 0;
    opacity: 1;
    border-radius: 5px;

    &--amount {
        top: 15px;
        left: 0;
        right: auto;

        @media screen and (max-width: 720px) {
            top: auto;
            bottom: 80px;
        }
        @media screen and (max-width: 600px) {
            bottom: 65px;
        }
        @media screen and (max-width: 540px) {
            bottom: 55px;
            left: 15px;
        }
    }
    &--under {
        position: static;
        top: 0;
        left: 0;
        right: auto;
        @include transform(none);

        & + & {
            margin-top: 1px;
        }
    }
}

.order-form__errors-container .validation-nok {
    padding: 4px 7px 5px;
}


/* odkaz na obchodni podminky a gdpr */

.order-form__tac-gdpr {
    margin-top: 15px;
    line-height: (18/12);
    font-size: 12px;
    letter-spacing: -.18px;

    @media screen and (min-width: 541px) {
        width: 50%;
    }
}

.order-form__tac-gdpr__link {
    font-weight: 700;
    &:hover {
        text-decoration: underline;
    }
}


/* obecne vyzivany sedy wrapper */

.order-form__box-wrapper {
    padding: 25px;
    font-size: 15px;
    font-weight: 500;
    background-color: #F2F2F2;

    @media screen and (max-width: 600px) {
        padding: 20px;
    }

    & + & {
        margin-top: 10px;
    }
}


/* sleva */

.form-order__discount {
    display: flex;
    align-items: center;
    background-color: #b3d1a4;
}

.form-order__discount-marker {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    text-align: center;
    font-weight: 700;
    background-color: #fff;
    border-radius: 50%;
}

.form-order__discount strong {
    font-weight: 700;
}


/* radio vyber pro dopravu a platbu */

.order-form__radio-wrapper {

    & + & {
        margin-top: 20px;

        @media screen and (max-width: 600px) {
            margin-top: 18px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 15px;
        }
    }
    &--csob {
        background: url("../web_images/ico_csob.svg") center right no-repeat;
        background-size: contain;
    }
}

.order-form__radio-label {
    display: flex;
    align-items: center;
}

.order-form__radio-input {
    display: none;
}

.order-form__radio-knob {
    flex-shrink: 0;
    position: relative;
    margin-right: 15px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    @include transition-custom(background-color);

    &:after {
        @include before-after();
        background-color: #B3D1A4;
        border-radius: 50%;
        @include transform(scale(0));
        @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .3s);
    }
    .order-form__radio-label:hover &:after {
        @include transform(scale(.55));
    }
    &--filled:after,
    .order-form__radio-label:hover &--filled:after,
    .order-form__radio-input:checked + &:after {
        @include transform(scale(1));
    }
}

.order-form__radio-price {
    margin-left: auto;
    padding-left: 15px;
    font-weight: 700;
}


/* souhrn */

.order-form__columns {
    display: flex;
}

.order-form__column {
    width: 50%;
}

.order-form__row {
    line-height: (25/15);
    font-size: 15px;
    font-weight: 500;

    &--empty {
        height: 15px;
    }
}


/* mnozstvi lahvi, ktere je treba objednat */

.order-form__progress {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 25px 45px;
    background-color: #E58181;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &--done {
        //background-color: #E58181;
        background-color: #B3D1A4;

        /*@include animation-full(bg-change forwards .4s ease-in-out);

        @include keyframes(bg-change) {
            0% {
                background-color: #E58181;
            }
            100% {
                background-color: #B3D1A4;
            }
        };*/
    }

    @media screen and (max-width: 768px) {
        padding: 25px 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 20px 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 18px 20px;
    }

    @include transition(all .3s cubic-bezier(0.22, 0.61, 0.36, 1));
    @include transform-origin(center bottom);

    &.animate {
        @include transform(scale(1.075));
    }
}

.order-form__progress__amount {
    line-height: 1;
    font-size: 20px;
    font-weight: 700;
}

.order-form__progress__para {
    line-height: (20/15);
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
}