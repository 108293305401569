
.collections {

    &:first-child {
        padding-top: var(--header-height);
    }
}

.collections__content {

}

.collections__heading-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #fff;

    .content-wrapper--beige & {
        color: #000;
    }
}

.collections__heading {
    font-family: 'Nikola', serif;
    font-size: 35px;
}

.collections__intro {
    line-height: (22/15);
    font-size: 15px;
    font-weight: 500;
    width: 360px;
    max-width: 50%;
}

@media screen and (max-width: 570px) {

    .collections__heading {
        width: 100%;
    }

    .collections__intro {
        margin-top: 20px;
        width: 100%;
        max-width: 360px;
    }
}

.collections__inner {
    margin-top: 50px;
    display: flex;
}


/* filtr */

.collections__filter {
    flex-shrink: 0;
    width: 165px;
    margin-right: 18px;
}

@media screen and (min-width: 768px) {

    .collections__filter-inner {
        position: sticky;
        top: var(--header-height);
    }
}

.collections__filter-item {
    position: relative;
    display: block;
    line-height: 45px;
    padding-right: 25px;
    font-size: 15px;
    color: #fff;
    border-bottom: 1px solid #1a1a1a;

    &.active {
        font-weight: 700;
    }

    opacity: 0;
    @include transition-custom(opacity);

    &.not-empty {
        opacity: 1;
    }

    .content-wrapper--beige & {
        color: #000;
        border-color: #d1c2b8;
    }

    &:after {
        @include before-after(0, auto, 19px);
        content: '\27F6';
        right: 0;
        @include transition-all();
        opacity: 0;
        @include transform(translateX(-5px));
    }
    &.active:after {
        opacity: 1;
        font-weight: 900;
        @include transform(translateX(0));
    }
    &:hover:after {
        opacity: 1;
        @include transform(translateX(0));
    }
}

@media screen and (max-width: 767px) {

    .collections__inner {
        flex-wrap: wrap;
        margin-top: 40px;
    }

    .collections__filter {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .collections__filter-item {
        line-height: 40px;
    }
}
@media screen and (max-width: 570px) {

    .collections__inner {
        margin-top: 30px;
    }
}


/* vypis kolekci */

.collections__listing {
    width: 100%;
}

.bottles-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.bottles {
    position: relative;

    &.hidden {
        display: none;
    }
}

.bottles__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 25px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    border: 1px solid #202020;

    .content-wrapper--beige & {
        color: #000;
        border-color: #cfc4bc;
    }

    &:hover {
        border-color: #3b3b3b;

        .content-wrapper--beige & {
            border-color: #ac9e94;
        }
    }
}

.bottles__img {
    width: 100%;
    padding-top: 275px;
    background: center no-repeat;
    background-size: contain;
}

.bottles__name {
    margin-top: 20px;
    line-height: (22/15);
    max-width: 250px;
    font-weight: 700;
}

.bottles__attr {
    line-height: (22/15);
}

.bottles__attr__sugar {
    position: relative;
    padding-left: 22px;
}

.bottles__attr__sugar-ico {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 17px;
    background: center no-repeat;
    background-size: contain;

    &--dry {
        background-image: url("../web_images/ico_dry.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_dry_w.svg");
        }
    }
    &--semidry {
        background-image: url("../web_images/ico_semidry.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_semidry_w.svg");
        }
    }
    &--sweet {
        background-image: url("../web_images/ico_sweet.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_sweet_w.svg");
        }
    }
    &--semisweet {
        background-image: url("../web_images/ico_semisweet.svg");
        .content-wrapper--dark & {
            background-image: url("../web_images/ico_semisweet_w.svg");
        }
    }
}

.bottles__btn-wrapper {
    margin-top: auto;
}

.bottles__btn {
    margin-top: 30px;
}

@media screen and (max-width: 1023px) {

    .bottles-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 570px) {

    .bottles-list {
        grid-template-columns: repeat(1, 1fr);
    }
}